import React from "react";
import { SSTotals } from "../../types/Totals";

const Row = (props: { data: SSTotals }) => {
  return (
    <tr className="totals-row">
      <td className="totals-value-col store">{props.data.storeName}</td>
      <td className="totals-value-col">{props.data.orderCount}</td>
      <td className="totals-value-col">
        {props.data.spend ? props.data.spend.toFixed(2) : 0}
      </td>
      <td className="totals-value-col">
        {
          /* (props.data.value / currency).toFixed(2) */ props.data.orderValue
            ? props.data.orderValue.toFixed(2)
            : 0
        }
      </td>
      <td className="totals-value-col">{props.data.itemsCount}</td>
      <td className="totals-value-col">
        {props.data.ROAS ? props.data.ROAS.toFixed(2) : 0}
      </td>
      <td className="totals-value-col">
        {props.data.CPP ? props.data.CPP.toFixed(2) : 0}
      </td>
      <td className="totals-value-col"></td>
    </tr>
  );
};

export default Row;
