import React from "react";
import { Currency } from "../../types/Currency";

const CurrencySelectUpdate = (props: {
  data: Currency[];
  setCurrency: any;
}) => {
  const handleChange = (e: any) => {
    props.setCurrency(JSON.parse(e.target.value));
  };
  return (
    <div className="input-container">
      <select onChange={handleChange}>
        <option disabled selected>
          Izberi valuto
        </option>
        {props.data.map((currency) => {
          return (
            <option value={JSON.stringify(currency)}>{currency.name}</option>
          );
        })}
      </select>
    </div>
  );
};

export default CurrencySelectUpdate;
