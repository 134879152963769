import React from "react";
import { Currency } from "../../types/Currency";
import CurrencyRow from "./CurrencyRow";

const CurrencyTable = (props: { data: Currency[] }) => {
  const makeTable = (): any[] => {
    const rows: any[] = [];
    props.data.map((currency) => {
      rows.push(<CurrencyRow data={currency} />);
    });
    return rows;
  };
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Valuta</th>
            <th>Tečaj</th>
          </tr>
        </thead>
        <tbody>{makeTable()}</tbody>
      </table>
    </div>
  );
};

export default CurrencyTable;
